import request from './services/axios.js'

// 邮件推送 -- 配置管理相关接口
// 获取当前配置
export function GetConfig(params) {
  return request({
    params,
    url: '/vas/push/configManage/getConfig',
    method: 'get'
  })
}

// 获取模板列表
export function GetConfigModals(params) {
  return request({
    params,
    url: '/vas/push/configManage/getTemplateStyles',
    method: 'get'
  })
}

// 修改信息提交
export function UpdateConfig(params) {
  return request({
    data: params,
    url: '/vas/push/configManage/update',
    method: 'post'
  })
}

// 获取图片路径
export function GetPicturePath(params) {
  return request({
    params: params,
    url: '/vas/push/configManage/getPicturePath',
    method: 'get'
  })
}
// 获取图片路径
export function getPictureStreamPath(params) {
  return request({
    params: params,
    url: '/vas/push/task/getPictureStreamPath',
    method: 'get'
  })
}
// 获取模板图片流数据
export const GetPictureStream = PROJECT_CONFIG.BASE_URL + '/vas/push/configManage/getPictureStream'
// 上传图片接口
export const UploadPictureStream = PROJECT_CONFIG.BASE_URL + '/vas/push/configManage/uploadBanner'

// 配置模版
export function getTemplate(params) {
  return request({
    params: params,
    url: '/vas/networktemplate/getTemplate',
    method: 'get'
  })
}

// 获取微网刊详细信息
export function submitWorktemplate(params) {
  return request({
    data: params,
    url: '/vas/networktemplate/edit',
    method: 'post'
  })
}
// 删除微网刊模版
export function DeleteConfig(params) {
  return request({
    data: params,
    url: '/vas/networktemplate/deleteConfig',
    method: 'post'
  })
}
// 微网刊样式模板
export function ListConfig(params) {
  return request({
    params,
    url: 'vas/networkjournal/listConfig',
    method: 'get'
  })
}
// 期刊模板查询默认本刊模板默认字段值
export function GetDefaultByPushTemplateStyleId(params) {
  return request({
    params,
    url: '/push/template/getDefaultByPushTemplateStyleId',
    method: 'get'
  })
}
//编辑器图片上传
export function uploadEditorImg(params) {
  return request({
    data: params,
    url: '/vas/push/configManage/uploadBanner',
    method: 'post'
  })
}
