<!-- Designer -->
<template>
  <div class="designer salesHeader-box">
    <div class="salesHeader-content">
      <h3>请上传邮件推送相关设计图</h3>
      <el-form class="designer-form" ref="designerForm" :model="designerForm" :rules="designerFormRules" label-width="220px">
        <el-form-item label="选择期刊：" prop="magId">
          <el-select v-model="designerForm.magId" placeholder="请选择">
            <el-option v-for="item in magList" :key="item.magId" :label="item.magName" :value="item.magId" />
          </el-select>
        </el-form-item>
        <el-form-item label="上传邮件推送模板中Banner：" size="zdy4" prop="bannerPicId">
          <div class="upload-img">
            <img :src="bannerSrc" alt />
            <el-upload
              class="banner-cover upload-demo"
              :action="UploadPictureStream"
              name="file"
              :show-file-list="false"
              accept=".png,.jpg,.jpeg"
              :auto-upload="true"
              :on-success="handleBannerSuccess"
            >
              <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
            </el-upload>
          </div>
        </el-form-item>
        <!-- <el-form-item label="上传邮件推送模板中Footer：" size="zdy4" prop="footerPicId">
          <div class="upload-img">
            <img :src="footerSrc" alt />
            <el-upload
              class="banner-cover upload-demo"
              :action="UploadPictureStream"
              name="file"
              :show-file-list="false"
              accept=".png,.jpg,.jpeg"
              :auto-upload="true"
              :on-success="handleFooterSuccess"
            >
              <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
            </el-upload>
          </div>
        </el-form-item> -->
        <el-form-item label="配色：" size="zdy7" prop="emailColor">
          <el-color-picker v-model="designerForm.emailColor" @active-change="colorChange"></el-color-picker>
        </el-form-item>
      </el-form>
      <el-button type="primary" size="big" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>
import { GetPictureStream, UploadPictureStream } from '@/api/configManage'
import { GetMagList, NewEditInfo } from '@/api/roleManagement'
import { colorHex } from '@/utils'
export default {
  name: 'Designer',
  data() {
    return {
      GetPictureStream: GetPictureStream,
      UploadPictureStream: UploadPictureStream,
      morImg: require('@/assets/img/img_default.png'),
      designerForm: {
        magName: '',
        magId: '',
        bannerPicId: '',
        // footerPicId: '',
        emailColor: ''
      },
      designerFormRules: {
        magId: [{ message: '请选择期刊', trigger: 'change', required: true }],
        bannerPicId: [{ message: '请上传邮件推送模板中Banner', trigger: 'blur', required: true }],
        // footerPicId: [{ message: '请上传邮件推送模板中footer', trigger: 'blur', required: true }],
        emailColor: [{ message: '请选择配色', trigger: 'change', required: true }]
      },
      bannerCover: '',
      // footerCover: '',
      magList: []
    }
  },
  props: {},
  components: {},
  computed: {
    bannerSrc() {
      return this.bannerCover ? this.bannerCover : this.designerForm.bannerPicId ? `${this.GetPictureStream}?pictureId=${this.designerForm.bannerPicId}` : this.morImg
    }
    // footerSrc() {
    //   return this.footerCover ? this.footerCover : this.designerForm.footerPicId ? `${this.GetPictureStream}?pictureId=${this.designerForm.footerPicId}` : this.morImg
    // }
  },
  mounted() {
    this.getMagList()
  },
  methods: {
    // 成功上传邮件推送模板中Banner
    handleBannerSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.bannerCover = URL.createObjectURL(file.raw)
      this.designerForm.bannerPicId = ID
      this.$refs.designerForm.validateField('bannerPicId')
    },
    // // 成功上传邮件推送模板中footer
    // handleFooterSuccess(res, file) {
    //   const { data } = res
    //   const { ID } = data
    //   this.footerCover = URL.createObjectURL(file.raw)
    //   this.designerForm.footerPicId = ID
    //   this.$refs.designerForm.validateField('footerPicId')
    // },
    // 配色选择
    colorChange(value) {
      // this.designerForm.emailColor = colorHex(value)
    },
    // 获取期刊列表
    getMagList() {
      let params = {}
      GetMagList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.magList = data.content
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 提交
    submit() {
      console.log(this.designerForm.emailColor)
      this.$refs.designerForm.validate((valid, object) => {
        if (!valid) {
          let message = Object.values(object)[0][0].message
          this.$message.error(message)
        } else {
          this.$confirm('此操作将上传已经提交的信息，请确保提交信息无误！如若无误，点击确定继续提交。', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'info'
          })
            .then(() => {
              let id
              this.magList.map((item) => {
                if (item.magId === this.designerForm.magId) {
                  this.designerForm.magName = item.magName
                  id = item.id
                }
              })
              let params = { id: id, ...this.designerForm }
              NewEditInfo(params).then((res) => {
                if (res && res.data && res.data.status === 0) {
                  const { message } = res.data
                  console.log(res.data, 'res.data')
                  this.$message.success(message)
                  this.getMagList()
                  this.designerForm = {
                    magName: '',
                    magId: '',
                    bannerPicId: '',
                    emailColor: ''
                  }
                  this.bannerCover = ''
                } else {
                  const { message = '' } = res.data
                  this.$message.error(message)
                }
              })
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消提交'
              })
            })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.designer {
  .designer-form {
    .el-form-item {
      margin-bottom: 40px;
    }
    .el-form-item__content {
      text-align: left;
      .el-select {
        width: 300px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.designer {
  .salesHeader-content {
    border-radius: 8px;
    min-height: 300px;
    padding: 40px;
    .upload-img,
    .upload-demo {
      width: 600px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .upload-img {
      background-color: rgb(244, 244, 244);
      border: 1px solid rgb(221, 221, 221);
    }
    .upload-demo {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 600px;
      height: 100px;

      .upload-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
    h3 {
      text-align: left;
    }
    .designer-form {
      margin-top: 50px;
    }
  }
}
</style>
