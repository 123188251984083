import request from './services/axios.js'
// 设计师列表查询
export function GetMagList(params) {
  return request({
    params,
    url: '/vas/flow/list',
    method: 'get'
  })
}
// 新建编辑各个角色管理信息
export function NewEditInfo(params) {
  return request({
    data:params,
    url: '/vas/flow/edit',
    method: 'post'
  })
}
// 期刊联想查询
export function GetQueryMagList(params) {
  return request({
    params,
    url: '/vas/flow/queryMag',
    method: 'get'
  })
}
// 产品页-期刊列表查询
export function GetPMMagList(params) {
  return request({
    params,
    url: '/vas/flow/listMag',
    method: 'get'
  })
}
// 产品页-销售列表查询
export function GetSaleMagList(params) {
  return request({
    params,
    url: '/vas/flow/listMember',
    method: 'get'
  })
}
// 产品页-运营负责人列表查询
export function GetOperateMemberList(params) {
  return request({
    params,
    url: '/vas/flow/operateMember/list',
    method: 'get'
  })
}
// 撤销通知
export function RevocationNotice(params) {
  return request({
    params,
    url: '/vas/flow/revoke',
    method: 'get'
  })
}
// 销售页-删除任务
export function DeleteSaleTask(params) {
  return request({
    params,
    url: '/vas/flow/delete',
    method: 'delete'
  })
}
// 产品页-推送数据一览-获取推送任务列表
export function PushTaskList(params) {
  return request({
    params,
    url: '/vas/push/task/pushTaskList',
    method: 'get'
  })
}
// 产品页-推送数据一览-获取已推送任务期刊
export function GetPushMag(params) {
  return request({
    params,
    url: '/vas/push/task/getPushMag',
    method: 'get'
  })
}
// 产品页-推送数据一览-推送任务设置数量统计详情
export function GetHistoryPushSetList(params) {
  return request({
    params,
    url: '/vas/push/task/getHistoryPushSetList',
    method: 'get'
  })
}
// 产品页-任务管理-服务状态修改
export function EditFlow(params) {
  return request({
    data:params,
    url: '/vas/flow/editFlow',
    method: 'post'
  })
}
// 产品页-推送数据一览-运营负责人
export function GetOperateMemberNames(params) {
  return request({
    params,
    url: '/vas/flow/operateMemberNames',
    method: 'get'
  })
}

// 产品页-推送数据一览-导出推送任务
export const ExportTaskInfo = PROJECT_CONFIG.BASE_URL + '/vas/push/task/exportTaskInfo'
// 产品页-推送数据一览-导出推送任务数量统计设置详情
export const ExportHistoryPushSetList = PROJECT_CONFIG.BASE_URL + '/vas/push/task/exportHistoryPushSetList'
